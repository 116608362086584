<template>
  <b-overlay :show="isLoading" variant="transparent" opacity="0.99" blur="5px" rounded="sm">
    <template #overlay>
      <div class="text-center">
        <b-icon-controller font-scale="3" animation="cylon" />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <b-card no-body>
      <b-card-title>
        <div class="p-2 d-flex justify-content-between">
          <span>คูปองทั้งหมด</span>
          <CreateModal :couponTypes="couponTypes" @submit="upsertCoupon" />
        </div>
        <div class="px-2">
          <b-form-group class="mr-1 flex-grow-1" label="ค้นหา" label-for="search">
            <b-form-input placeholder="Code.." debounce="500" @update="getCoupons(1, $event)"></b-form-input>
          </b-form-group>
        </div>
      </b-card-title>
      <b-table small striped hover responsive show-empty class="position-relative items-center" :fields="fields"
        :items="coupons">
        <template #empty="scope">
          <div class="p-2 text-center">
            <img src="/box.png" alt="empty" height="40px"> {{ scope.emptyFiltered ? 'No matching records found' :
    'ไม่พบข้อมูล' }}
          </div>
        </template>
        <template #cell(code)="row">
          {{ row.item.code }}
        </template>
        <template #cell(type)="row">
          {{ row.item.type.name_th }}
        </template>
        <template #cell(reward)="row">
          {{ row.item.reward }}
        </template>
        <template #cell(amount)="row">
          {{ row.item.amount }}
        </template>
        <template #cell(actions)="row">
          <EditModal :couponTypes="couponTypes" :data="row.item" @submit="upsertCoupon"/>
        </template>
        <!-- <template #cell(status)="row">
          <b-badge v-if="row.item.status == 0" pill>รอ</b-badge>
          <b-badge v-if="row.item.status == 1" pill variant="info">ดำเนินการ</b-badge>
          <b-badge v-if="row.item.status == 2" pill variant="success">สำเร็จ</b-badge>
        </template> -->
      </b-table>
      <b-card-body v-if="data" class="d-flex justify-content-between flex-wrap pt-0">
        <b-pagination v-model="data.page" :total-rows="data.total" :per-page="data.perPage" first-number last-number
          prev-class="prev-item" next-class="next-item" class="mb-0">
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import CreateModal from './create.vue';
import EditModal from './edit.vue';
import {
  BCardTitle, BIcon, BDropdownItem, BDropdown, BBadge, BOverlay, BIconController, BInputGroupPrepend, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton, BCardBody,

} from 'bootstrap-vue'

export default {
  components: {
    BCardTitle, BIcon, BDropdownItem, BDropdown, BBadge, BOverlay, BIconController, BInputGroupPrepend, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton, BCardBody,
    CreateModal, EditModal
  },
  // props: ['items'],
  // emits: [],
  // filters: {
  //   convertDate(date) {
  //     return moment(date).tz('Asia/Bangkok').format('DD/MM/YYYY HH:ss')
  //   },
  // },
  data: () => ({
    isLoading: false,
    fields: [
      { key: 'code', label: 'Code' },
      { key: 'type', label: 'ประเภท' },
      { key: 'reward', label: 'รางวัล' },
      { key: 'amount', label: 'จำนวนที่เหลือ' },
      { key: 'actions', label: '#' },
    ],
    data: null,
    coupons: [],
    couponTypes: []
  }),
  mounted() {
    this.getCoupons();
    this.getCouponTypes();
  },
  methods: {
    async upsertCoupon(form) {
      this.isLoading = true;
      try {
        const { data } = await this.$http.post('/function/coupon', form);

        this.getCoupons();
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
    // async submit(form) {
    //   this.isLoading = true;
    //   try {
    //     const { data } = await this.$http.post('/function/coupon/list', form);

    //     this.getCoupons();
    //   } catch (e) {
    //     console.log(e);
    //   } finally {

    //     this.isLoading = false;
    //   }
    // },  
    async getCoupons(page = 1, code) {
      console.log(page, code)
      this.isLoading = true;
      try {
        const { data } = await this.$http.get('/function/coupon/list', {
          params: {
            page: page,
            code: code
          }
        });

        this.coupons = data.data;
        this.data = data;
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
    async getCouponTypes() {
      this.isLoading = true;
      try {
        const { data } = await this.$http.get('/function/coupon/types');

        this.couponTypes = data;
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    }
  }
}
</script>